import './App.css'
import Landing from './components/Landing'

function App({
	descriptionDefault,
	languageSelector,
	defaultLanguage,
	editorHeight,
	outputHeight,
	totalWidth,
}) {
	return (
		<Landing
			descriptionDefault={descriptionDefault}
			languageSelector={languageSelector}
			defaultLanguage={defaultLanguage}
			editorHeight={editorHeight}
			outputHeight={outputHeight}
			totalWidth={totalWidth}
		/>
	)
}

export default App
