import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import App from './App'
import './index.css'

const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<App
				descriptionDefault={''}
				languageSelector={true}
				defaultLanguage={'python'}
				editorHeight={'240px'} //Default 240x
				outputHeight={'80px'}
				totalWidth={'100%'}
			/>
		),
	},
	{
		path: '/ide/python/template',
		element: (
			<App
				descriptionDefault={'descriptionDefault'}
				languageSelector={false}
				defaultLanguage={'python'}
				editorHeight={'160px'} //Default 240x
				outputHeight={'80px'}
				totalWidth={'100%'}
			/>
		),
	},
])

ReactDOM.createRoot(document.getElementById('root')).render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
)
