import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { languageOptions } from '../constants/languageOptions'
import { classnames } from '../utils/general'
import CodeEditorWindow from './CodeEditorWindow'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import useKeyPress from '../hooks/useKeyPress'
import { defineTheme } from '../lib/defineTheme'
import LanguagesDropdown from './LanguagesDropdown'
import OutputDetails from './OutputDetails'
import OutputWindow from './OutputWindow'
import ThemeDropdown from './ThemeDropdown'

// const descriptionDefault = `
// //Try to solve the next task using only print function!
// `

const Landing = ({
	descriptionDefault,
	languageSelector,
	defaultLanguage,
	editorHeight,
	outputHeight,
	totalWidth,
}) => {
	const [code, setCode] = useState(descriptionDefault)
	const [customInput, setCustomInput] = useState('')
	const [outputDetails, setOutputDetails] = useState(null)
	const [processing, setProcessing] = useState(null)
	const [theme, setTheme] = useState('cobalt')
	const [language, setLanguage] = useState(languageOptions[0])

	const enterPress = useKeyPress('Enter')
	const ctrlPress = useKeyPress('Control')

	const onSelectChange = sl => {
		console.log('selected Option...', sl)
		setLanguage(sl)
	}

	useEffect(() => {
		if (enterPress && ctrlPress) {
			console.log('enterPress', enterPress)
			console.log('ctrlPress', ctrlPress)
			handleCompile()
		}
	}, [ctrlPress, enterPress])
	const onChange = (action, data) => {
		switch (action) {
			case 'code': {
				setCode(data)
				break
			}
			default: {
				console.warn('case not handled!', action, data)
			}
		}
	}
	const handleCompile = () => {
		setProcessing(true)
		const formData = {
			language_id: language.id,
			// encode source code in base64
			source_code: window.btoa(unescape(encodeURIComponent(code))),
			stdin: window.btoa(unescape(encodeURIComponent(customInput))),
		}
		const options = {
			method: 'POST',
			url: process.env.REACT_APP_RAPID_API_URL,
			params: { base64_encoded: 'true', fields: '*' },
			headers: {
				'content-type': 'application/json',
				'Content-Type': 'application/json',
				//'X-RapidAPI-Host': process.env.REACT_APP_RAPID_API_HOST,
				'X-Auth-Token': process.env.REACT_APP_RAPID_API_KEY,
			},
			data: formData,
		}
		axios
			.request(options)
			.then(function (response) {
				const token = response.data.token
				checkStatus(token)
			})
			.catch(err => {
				console.log(err)
				let error = err.response ? err.response.data : err
				// get error status
				let status = err.response.status
				console.log('status', status)
				if (status === 429) {
					console.log('too many requests', status)

					showErrorToast(
						`Quota of 100 requests exceeded for the Day! Please read the blog on freeCodeCamp to learn how to setup your own RAPID API Judge0!`,
						10000
					)
				}
				setProcessing(false)
				console.log('catch block...', error)
			})
	}

	const checkStatus = async token => {
		const options = {
			method: 'GET',
			url: process.env.REACT_APP_RAPID_API_URL + token,
			params: { base64_encoded: 'true', fields: '*' },
			headers: {
				//'X-RapidAPI-Host': process.env.REACT_APP_RAPID_API_HOST,
				'X-Auth-Token': process.env.REACT_APP_RAPID_API_KEY,
			},
		}
		try {
			let response = await axios.request(options)
			let statusId = response.data.status?.id

			// Processed - we have a result
			if (statusId === 1 || statusId === 2) {
				// still processing
				setTimeout(() => {
					checkStatus(token)
				}, 2000)
				return
			} else {
				setProcessing(false)
				setOutputDetails(response.data)
				if (response.data.status.id == 11)
					showErrorToast(response.data.status.description)
				else showSuccessToast(`Compiled Successfully!`)
				return
			}
		} catch (err) {
			console.log('err', err)
			setProcessing(false)
			showErrorToast()
		}
	}

	function handleThemeChange(th) {
		const theme = th
		console.log('theme...', theme)

		if (['light', 'vs-dark'].includes(theme.value)) {
			setTheme(theme)
		} else {
			defineTheme(theme.value).then(_ => setTheme(theme))
		}
	}
	useEffect(() => {
		defineTheme('oceanic-next').then(_ =>
			setTheme({ value: 'oceanic-next', label: 'Oceanic Next' })
		)
	}, [])

	const showSuccessToast = msg => {
		toast.success(msg || `Compiled Successfully!`, {
			position: 'top-right',
			autoClose: 2000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		})
	}
	const showErrorToast = (msg, timer) => {
		toast.error(msg || `Something went wrong! Please try again.`, {
			position: 'top-right',
			autoClose: timer ? timer : 2000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		})
	}

	return (
		<>
			<ToastContainer
				position='top-right'
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<div className='h-4 w-full bg-gradient-to-r from-blue-500 via-cyan-400 to-gray-50'></div>
			{languageSelector ? (
				<div className='flex flex-row'>
					<div className='px-4 py-2'>
						<LanguagesDropdown onSelectChange={onSelectChange} />
					</div>
					<div className='px-4 py-2'>
						<ThemeDropdown
							handleThemeChange={handleThemeChange}
							theme={theme}
						/>
					</div>
				</div>
			) : (
				''
			)}
			<div
				className='flex flex-col space-y-4 px-4 py-4'
				style={{ width: totalWidth }}
			>
				{/* Main Window - Top Row */}
				<div
					className={`flex w-[100%] justify-start`}
					style={{ height: editorHeight }}
				>
					<CodeEditorWindow
						code={code}
						onChange={onChange}
						language={language?.value}
						theme={theme.value}
					/>
				</div>

				{/* Result Windows - Second Row */}
				<div
					className='flex flex-row space-x-4 w-full '
					style={{ height: outputHeight }}
				>
					<div className='flex flex-1 '>
						<OutputWindow outputDetails={outputDetails} />
					</div>
					<div className='flex flex-row '>
						<OutputDetails outputDetails={outputDetails} />
					</div>
				</div>
				<div>
					<button
						onClick={handleCompile}
						disabled={!code}
						className={classnames(
							'border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white h-full',
							!code ? 'opacity-50' : ''
						)}
					>
						{processing ? 'Processing...' : 'Compile and Execute'}
					</button>
				</div>

				{/* Buttons - Third Row */}
				{/*<div className='flex flex-row space-x-4 w-full justify-start items-center'>
					 <CustomInput
						customInput={customInput}
						setCustomInput={setCustomInput}
					/> 
				</div>*/}
			</div>
			{/* 
			<Footer /> */}
		</>
	)
}
export default Landing
